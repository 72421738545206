import * as React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import Layout from '../../components/layout/layout'
import Mediapost from "../../components/mediapost/mediapost"

import parse from 'html-react-parser'

const pageTitle = "Videos 視頻"

const IndexPage = () => {
    const query = graphql`
      {
        restApiVideosApi {
          objects {
              creation_date
              creation_date_timestamp
              description_en
              dimension_height
              dimension_width
              full_size_url
              id
              link
              published_date
              slug
              summary_en
              thumbnail_url
              title_en
              video_type
          }
        }
      }`

    const objects = useStaticQuery(query)

    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <h1 className="pt-4 pb-4">
                            {pageTitle}
                        </h1>
                        <div className="row">
                            {objects && objects.restApiVideosApi && objects.restApiVideosApi.objects ? objects.restApiVideosApi.objects.map(video => (
                                <div className="col-md-4 col-6">
                                    <Link to={`/videos/${video.slug}/`} className="blogPost">
                                        <div className="card mb-3">
                                            <img src={video.thumbnail_url} className="img-fluid"/>

                                            <div className="card-body min-height-100 pb-0 font-size-12">
                                                <h5 className="card-title">{video.title_en}</h5>
                                                <p className="card-text text-muted font-size-12">{video.creation_date}</p>

                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )) : null}
                        </div>


                    </div>
                </div>
            </div>


        </Layout>

    )
}

export default IndexPage
